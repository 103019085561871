import clsx from "clsx";
import { useLocation, useSubmit } from "@remix-run/react";
import {
	Menu,
	MenuButton,
	MenuItem,
	MenuPopover,
	MenuItems,
} from "@reach/menu-button";
import { getLanguageLocaleKey, Dictionary } from "~/utils/language";
import type { Language } from "~/utils/language";
import { usePageAvailableLocales, useRootData } from "~/hooks/common";
import { Icon } from "~/components/ui/icons";
import { Paragraph } from "./ui/typography";
import { useSharedContent } from "~/hooks/localization";
import { spTrackWebInteraction } from "~/utils/tracking";

interface Props {
	iconOnly?: boolean;
	color?: string;
}

export function LanguageSelector({ iconOnly = false }: Props) {
	const location = useLocation();
	const submit = useSubmit();

	const { locale: activeLocale } = useRootData();
	const { t } = useSharedContent(Dictionary.LANGUAGE);
	const availableLocales = usePageAvailableLocales();

	const langItems: Array<Language> = ["en", ...availableLocales];

	function onLanguageChange(locale: Language) {
		const form = new FormData();
		form.append("pathName", location.pathname);
		form.append("search", location.search);
		form.append("locale", locale);

		submit(form, {
			method: "post",
			replace: true,
			action: "/api/set-language",
		});

		spTrackWebInteraction({
			object: "language",
			action: "switch",
			value: locale,
		});
	}

	return (
		<div className="flex items-center gap-4">
			<Menu>
				<MenuButton>
					<span className="sr-only">Change language</span>
					<div className="flex items-center gap-3 p-2 text-theme-primary dark:text-grey-40">
						<Icon name="language" />
						{!iconOnly ? (
							<>
								<Paragraph color="primary">
									{t(getLanguageLocaleKey(activeLocale))}
								</Paragraph>
								<Icon
									name="chevron-down"
									width="11"
									height="6"
								/>
							</>
						) : null}
					</div>
				</MenuButton>
				<MenuPopover className="z-50">
					<MenuItems className="border-stroke bg-primary rounded-lg border">
						{langItems.map((item) => {
							const active = item === activeLocale;

							return (
								<MenuItem
									className={clsx(
										"hover:bg-secondary hover:text-current"
									)}
									key={item}
									onSelect={() => onLanguageChange(item)}
								>
									<Paragraph
										fontWeight="font-medium"
										className={
											active
												? "text-theme-primary"
												: "text-primary"
										}
									>
										{t(getLanguageLocaleKey(item))}
									</Paragraph>
								</MenuItem>
							);
						})}
					</MenuItems>
				</MenuPopover>
			</Menu>
		</div>
	);
}
